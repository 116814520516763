var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"registers",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('confirm-dialog',{ref:"confirmDelete",attrs:{"title":"Delete Register?"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.virtualRegisterHeaders,"items":_vm.currentVirtualRegisters,"loading":_vm.registersLoading,"loading-text":"Loading... One moment"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"75vw","hide-overlay":"","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.handleRegisterAdd}},on),[_vm._v(" New Virtual Register ")])]}}],null,true),model:{value:(_vm.registerDialog),callback:function ($$v) {_vm.registerDialog=$$v},expression:"registerDialog"}},[_c('v-sheet',{staticClass:"pa-4 mt-2",attrs:{"elevation":"4"}},[_c('v-sheet',{staticClass:"pa-4 mt-2",attrs:{"elevation":"4"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('validated-text-field',{attrs:{"label":"Virtual Register Name","rules":"required","disabled":_vm.registerAction === 'Display'},model:{value:(_vm.registerName),callback:function ($$v) {_vm.registerName=$$v},expression:"registerName"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('validated-select-field',{attrs:{"items":_vm.registerUnitOfMeasures,"item-text":function (item) { return item.registerUnitOfMeasureName; },"item-value":function (item) { return item.id; },"return-object":true,"label":"Register Unit Of Measure","rules":"required","disabled":_vm.registerAction === 'Display' ||
                    (_vm.registerAction === 'Add' &&
                      _vm.currentCalculatedRegisters.length > 0)},on:{"input":_vm.handleSelectRegisterUnitOfMeasure},model:{value:(_vm.selectedRegisterUnitOfMeasure),callback:function ($$v) {_vm.selectedRegisterUnitOfMeasure=$$v},expression:"selectedRegisterUnitOfMeasure"}})],1)],1)],1),_c('v-card',{staticClass:"mt-2"},[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.calculatedRegisterHeaders,"items":_vm.currentCalculatedRegisters,"loading":_vm.registersLoading,"loading-text":"Loading... One moment"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"75vw","hide-overlay":"","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"color":"primary","text":"","disabled":!_vm.validForRegisterRegister},on:{"click":_vm.handleRegisterRegisterAdd}},on),[_vm._v(" Add Calculated Register ")])]}}],null,true),model:{value:(_vm.registerRegisterDialog),callback:function ($$v) {_vm.registerRegisterDialog=$$v},expression:"registerRegisterDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.registerAction)+" Calculated Register")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('validated-select-field',{attrs:{"items":_vm.physicalRegisters,"item-text":function (item) { return item.registerName; },"return-object":true,"label":"Calculated Register","rules":"required"},model:{value:(_vm.currentCalculatedRegisterRegister),callback:function ($$v) {_vm.currentCalculatedRegisterRegister=$$v},expression:"currentCalculatedRegisterRegister"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('validated-select-field',{attrs:{"items":_vm.operations,"label":"Operation","rules":"required"},model:{value:(_vm.currentCalculatedRegisterOperation),callback:function ($$v) {_vm.currentCalculatedRegisterOperation=$$v},expression:"currentCalculatedRegisterOperation"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.cancelRegisterDialog}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":invalid},on:{"click":_vm.handleSaveRegisterRegister}},[_vm._v(" Save ")])],1)],1)],1)]},proxy:true},{key:"item.id",fn:function(ref){
                      var item = ref.item;
return [_c('td',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.handleRegisterRegisterEdit(item)}}},[_c('v-icon',{attrs:{"medium":""}},[_vm._v("edit")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.handleRegisterRegisterDelete(item)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)],1)]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.cancelDialog}},[_vm._v(" Cancel ")]),(_vm.registerAction !== 'Display')?_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.handleSaveRegister}},[_vm._v(" Save ")]):_vm._e()],1)],1)],1)],1)]},proxy:true},{key:"item.id",fn:function(ref){
                      var item = ref.item;
return [_c('td',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.handleRegisterDisplay(item)}}},[_c('v-icon',{attrs:{"medium":""}},[_vm._v("visibility")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.handleRegisterDelete(item)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }