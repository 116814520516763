<template>
  <v-container grid-list-xl>
    <p class="headline pt-3">Edit Virtual Monitor</p>
    <validation-observer ref="obs" v-slot="{ invalid }">
      <v-form @submit.prevent="submit" v-model="valid">
        <v-sheet elevation="2" class="pa-4">
          <v-row>
            <v-col sm="6">
              <validated-text-field
                v-model="monitor.deviceName"
                label="Device Name"
                hint="Name for this Device"
                persistent-hint
                rules="required"
                ref="nameText"
              ></validated-text-field
            ></v-col>
            <v-col sm="6">
              <validated-text-field
                v-model="monitor.friendlyName"
                label="Monitor Display Name"
                hint="Friendly Name for this virtual monitor"
                persistent-hint
                rules="required"
                ref="friendlyNameText"
              ></validated-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col sm="6" class="mt-3">
              <site-location-field
                v-if="finishedLoading"
                :siteId="siteId"
                :selectedSiteLocationId="monitor.siteLocationId"
                @sitelocationselected="siteLocationSelected"
                ref="siteLocation"
              ></site-location-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn type="submit" color="primary" :disabled="!isValidVirtualMonitor">Save</v-btn>
              <v-btn @click="handleCancelCrud" class="ml-5">Cancel</v-btn>
            </v-col>
          </v-row>
        </v-sheet>
        <v-sheet elevation="4" class="pa-4 mt-2">
          <v-tabs right v-model="active_tab">
            <v-tab :key="registerTab">Virtual Registers</v-tab>
            <v-tab-item :key="registerTab">
              <register
                :registers="registers"
                :siteId="siteId"
                :monitorId="monitorId"
                @createregister="createRegister"
                @deleteregister="deleteRegister"
              ></register>
            </v-tab-item>
          </v-tabs>
        </v-sheet>

        <v-row justify="center">
            <stepper-validate 
            :invalid="invalid"
            :monitorName="monitor.deviceName"
            :friendlyName="monitor.friendlyName"
            :siteLocation="selectedSiteLocation"
            @dismissdialog = "dismissDialog"
            @fixmeter = "fixMeterErrors"
            ref="stepper"></stepper-validate>
        </v-row>
      </v-form></validation-observer
    ></v-container
  >
</template>

<script>
import goTo from "vuetify/es5/services/goto";
import { ValidationObserver } from "vee-validate";
import ValidatedTextField from "@/components/Fields/ValidatedTextField";
import SiteLocationField from "@/modules/site/site_location/_components/SiteLocation";
import StepperValidate from "./StepperValidate.vue"
import Register from "./Register";
import api from "../_api";

export default {
  components: {
    "validated-text-field": ValidatedTextField,
    "validation-observer": ValidationObserver,
    "site-location-field": SiteLocationField,
    "stepper-validate": StepperValidate,
    register: Register,
  },

  data() {
    return {
      valid: true,
      monitor: {
        siteId: this.$route.params.id,
        deviceName: null,
        friendlyName: null,
        siteLocationId: null,
      },
      isValidVirtualMonitor: true,
      isValidating: false,

      active_tab: 0,
      registerTab: null,

      siteId: null,
      monitorId: null,

      selectedMonitor: null,
      selectedSiteLocation: null,
      monitors: [],
      siteLocations: [],
      registers: [],
      finishedLoading: false,
    };
  },

  async created() {
    this.siteId = this.$route.params.siteId;
    this.monitorId = this.$route.params.virtualMonitorId;
    this.registers = await api.getVirtualRegistersByMonitorId(this.monitorId);
    this.monitor = await api.getVirtualMonitorById(this.monitorId);
    try {
      await this.getLookups(this.siteId);
    } catch (error) {
      console.error(error);
    }
    this.finishedLoading = true;
  },

  methods: {

    async submit() {
      let valid = await this.$refs.obs.validate();
      if (valid === false) {
        return;
      }
      this.$refs.stepper.validateMeter();
    },

    async getLookups() {
      this.monitors = await api.getMonitorsForSite(this.siteId);
    },

    async handleSetMonitor(monitorId) {
      console.log(monitorId);
    },

    siteLocationSelected(selectedSiteLocation) {
      this.selectedSiteLocation = selectedSiteLocation;
    },

    fixMeterErrors(item) {
      switch (item) {
        case 1:
          this.$nextTick().then(() => {
            this.$refs.nameText.focus();
          });
          break;
        case 3:
          this.$nextTick().then(() => {
            this.$refs.siteLocation.$refs.siteLocation.focus();
          });
          break;
        default:
          break;
      }
    },

    async dismissDialog(isValid) {
      if (isValid) {
        goTo(0); //scroll up
        this.loading = true;
        try {
          this.monitor.siteLocationId = this.selectedSiteLocation?.id;
          await api.updateVirtualMonitor(this.monitor);
          this.$router.push({ name: "VirtualMonitorIndex" });
        } catch (error) {
          console.log(error);
        }
        this.loading = false;
      }
    },

    //post the new register passed from the register component
    async createRegister(register) {
      await api.createVirtualRegister(register);
      this.registers = await api.getVirtualRegistersByMonitorId(this.monitorId);
    },

    async deleteRegister(register) {
      try {
        await api.deleteVirtualRegister(register);
        this.registers = await api.getVirtualRegistersByMonitorId(
          this.monitorId
        );
      } catch (error) {
        console.log(error);
      }
    },

    handleCancelCrud() {
      this.$router.push({name: "VirtualMonitorIndex"});
    }
    
  },
};
</script>