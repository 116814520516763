<template>
  <validation-observer ref="registers" v-slot="{ invalid }">
    <confirm-dialog
      ref="confirmDelete"
      title="Delete Register?"
    ></confirm-dialog>
    <v-data-table
      :headers="virtualRegisterHeaders"
      :items="currentVirtualRegisters"
      class="elevation-1"
      :loading="registersLoading"
      :loading-text="`Loading... One moment`"
    >
      <template v-slot:top>
        <v-dialog
          v-model="registerDialog"
          max-width="75vw"
          hide-overlay
          persistent
        >
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-on="on"
              @click="handleRegisterAdd"
            >
              New Virtual Register
            </v-btn>
          </template>

          <v-sheet elevation="4" class="pa-4 mt-2">
            <v-sheet elevation="4" class="pa-4 mt-2">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <validated-text-field
                    v-model="registerName"
                    label="Virtual Register Name"
                    rules="required"
                    :disabled="registerAction === 'Display'"
                  ></validated-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <validated-select-field
                    :items="registerUnitOfMeasures"
                    :item-text="(item) => item.registerUnitOfMeasureName"
                    :item-value="(item) => item.id"
                    v-model="selectedRegisterUnitOfMeasure"
                    :return-object="true"
                    label="Register Unit Of Measure"
                    rules="required"
                    @input="handleSelectRegisterUnitOfMeasure"
                    :disabled="
                      registerAction === 'Display' ||
                      (registerAction === 'Add' &&
                        currentCalculatedRegisters.length > 0)
                    "
                  ></validated-select-field>
                </v-col>
              </v-row>
            </v-sheet>

            <v-card class="mt-2">
              <v-card-text>
                <v-data-table
                  :headers="calculatedRegisterHeaders"
                  :items="currentCalculatedRegisters"
                  class="elevation-1"
                  :loading="registersLoading"
                  :loading-text="`Loading... One moment`"
                >
                  <template v-slot:top>
                    <v-dialog
                      v-model="registerRegisterDialog"
                      max-width="75vw"
                      hide-overlay
                      persistent
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          color="primary"
                          text
                          class="mb-2"
                          v-on="on"
                          @click="handleRegisterRegisterAdd"
                          :disabled="!validForRegisterRegister"
                        >
                          Add Calculated Register
                        </v-btn>
                      </template>

                      <v-card>
                        <v-card-title>
                          <span class="headline"
                            >{{ registerAction }} Calculated Register</span
                          >
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="6" md="6">
                                <validated-select-field
                                  :items="physicalRegisters"
                                  :item-text="(item) => item.registerName"
                                  v-model="currentCalculatedRegisterRegister"
                                  :return-object="true"
                                  label="Calculated Register"
                                  rules="required"
                                ></validated-select-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="6">
                                <validated-select-field
                                  :items="operations"
                                  v-model="currentCalculatedRegisterOperation"
                                  label="Operation"
                                  rules="required"
                                ></validated-select-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="cancelRegisterDialog"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="handleSaveRegisterRegister"
                            :disabled="invalid"
                          >
                            Save
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog></template
                  >
                  <template v-slot:[`item.id`]="{ item }">
                    <td>
                      <v-btn @click="handleRegisterRegisterEdit(item)" icon>
                        <v-icon medium>edit</v-icon>
                      </v-btn>
                      <v-btn icon @click="handleRegisterRegisterDelete(item)">
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </td>
                  </template>
                </v-data-table>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="cancelDialog">
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="handleSaveRegister"
                  v-if="registerAction !== 'Display'"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-sheet>
        </v-dialog>
      </template>

      <template v-slot:[`item.id`]="{ item }">
        <td>
          <v-btn @click="handleRegisterDisplay(item)" icon>
            <v-icon medium>visibility</v-icon>
          </v-btn>
          <v-btn icon @click="handleRegisterDelete(item)">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </td>
      </template></v-data-table
    ></validation-observer
  >
</template>

<script>
import ValidatedSelectFieldVue from "@/components/Fields/ValidatedSelectField.vue";
import ValidatedTextField from "@/components/Fields/ValidatedTextField";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import { ValidationObserver } from "vee-validate";
import api from "./../_api";

export default {
  components: {
    "validated-text-field": ValidatedTextField,
    "validated-select-field": ValidatedSelectFieldVue,
    "validation-observer": ValidationObserver,
    "confirm-dialog": ConfirmDialog,
  },

  props: {
    registers: { type: Array, default: () => [] },
    siteId: { type: String, default: () => null },
    monitorId: { type: String, default: () => null },
  },

  watch: {
    registers(newVal) {
      console.log("registers updated");
      this.currentVirtualRegisters = newVal;
    },
  },

  computed: {
    calculatedRegisterHeaders() {
      let headers = [
        { text: "Register Name", value: "registerName" },
        {
          text: "Operation",
          value: "operation",
        },
      ];

      if (this.registerAction == "Add" || this.registerAction == "Edit") {
        headers.push({ text: "Actions", value: "id", sortable: false });
      }
      return headers;
    },
  },

  data() {
    return {
      validForRegisterRegister: false,
      currentVirtualRegisters: [],
      currentVirtualRegister: {},
      currentCalculatedRegisters: [],
      currentCalculatedRegister: {
        physicalRegister: { registerName: null, registerId: null },
        operation: null,
      },
      currentCalculatedRegisterRegister: null,
      currentCalculatedRegisterOperation: null,
      physicalRegisters: [],
      registerUnitOfMeasures: [],
      operations: ["Plus", "Minus"],
      selectedOperation: null,
      selectedPhysicalRegister: null,
      selectedRegisterUnitOfMeasure: null,
      selectedDeviceSubType: 2, //default to eguage

      registerAction: "Add",
      registerName: null,
      registerDialog: false,
      registerRegisterDialog: false,
      registersLoading: false,
      virtualRegisterHeaders: [
        { text: "Register Name", value: "registerName" },
        {
          text: "Register Unit Of Measure",
          value: "registerUnitOfMeasureName",
        },
        { text: "Actions", value: "id", sortable: false },
      ],
    };
  },

  async created() {
    this.currentRegisters = this.registers;
    this.registerUnitOfMeasures = await api.getRegisterUnitOfMeasures(
      this.selectedDeviceSubType
    );
  },

  methods: {
    async handleSaveRegister() {
      if (this.registerAction === "Add") {
        if (this.currentCalculatedRegisters.length < 2) {
          this.$toast.show(
            "At least 2 Calculated Registers are required",
            10000,
            "error"
          );
          return;
        }
        await this.createRegister();
      } else {
        this.updateRegister();
      }
    },

    async handleSaveRegisterRegister() {
      let validRegister = await this.$refs.registers.validate();
      let calcRegister = {
        registerName: this.currentCalculatedRegisterRegister.registerName,
        registerId: this.currentCalculatedRegisterRegister.registerId,
        operation: this.currentCalculatedRegisterOperation,
      };

      if (this.registerAction === "Add") {
        if (validRegister) {
          calcRegister.id = Date.now();
          this.currentCalculatedRegisters.push(calcRegister);
        } else {
          return;
        }
      } else {
        calcRegister.id = this.currentCalculatedRegister.id;
        let updated = this.currentCalculatedRegisters.map((el) =>
          el.id === this.currentCalculatedRegister.id ? calcRegister : el
        );
        this.currentCalculatedRegisters = updated;
      }

      this.registerRegisterDialog = false;
    },

    async handleSelectRegisterUnitOfMeasure(unitOfMeasure) {
      this.physicalRegisters = await api.getCalculatedRegistersBySiteIdAndRegisterUnitOfMeasure(
        this.siteId,
        unitOfMeasure.id
      );
      this.validForRegisterRegister = true;
    },

    async createRegister() {
      let valid = await this.$refs.registers.validate();
      if (valid === false) {
        return;
      }

      this.register = {
        siteId: this.siteId,
        registerName: this.registerName,
        monitorId: this.monitorId,
        registerUnitOfMeasureId: this.selectedRegisterUnitOfMeasure.id,
        createCalculatedRegisterResourceModels: this.currentCalculatedRegisters,
      };
      this.$emit("createregister", this.register);
      this.registerDialog = false;
    },

    async updateRegister() {
      let valid = await this.$refs.registers.validate();
      if (valid === false) {
        return;
      }

      this.$emit("updateregister", this.register);
      this.registerDialog = false;
    },

    async deleteRegister(register) {
      this.$emit("deleteregister", register);
      this.registerDialog = false;
    },

    deleteRegisterRegister(register) {
      let updated = this.currentCalculatedRegisters.filter(
        (r) => r.id !== register.id
      );
      this.currentCalculatedRegisters = updated;
      this.registerRegisterDialog = false;
    },

    cancelDialog() {
      this.registerDialog = false;
      this.validForRegisterRegister = false;
    },

    cancelRegisterDialog() {
      this.registerRegisterDialog = false;
    },

    async handleRegisterAdd() {
      const self = this;
      this.registerAction = "Add";
      await this.$refs.registers.reset();
      this.registerName = null;
      this.currentCalculatedRegisters = [];
      
      if (this.currentVirtualRegisters.length > 0) {
        const currentUOMSelected =
          self.currentVirtualRegisters[0].registerUnitOfMeasureName;
        //initialize add with preselected registerUOM
        const UOM = self.registerUnitOfMeasures.find(
          (x) => x.registerUnitOfMeasureName === currentUOMSelected
        );
        this.selectedRegisterUnitOfMeasure = UOM?.id;
        self.handleSelectRegisterUnitOfMeasure(UOM);
      } else {
        this.selectedRegisterUnitOfMeasure = null;
      }
    },

    async handleRegisterRegisterAdd() {
      await this.$refs.registers.reset();
      this.currentCalculatedRegister = {};
      this.currentCalculatedRegisterRegister = null;
      this.currentCalculatedRegisterOperation = null
      this.registerAction = "Add";
    },

    async handleRegisterDisplay(register) {
      this.registerAction = "Display";

      let readOnly = await api.getVirtualRegisterByRegisterId(
        register.registerId
      );

      this.registerName = readOnly.registerName;
      this.selectedDeviceSubType = readOnly.sourceDeviceSubtypeId;
      this.registerUnitOfMeasures = await api.getRegisterUnitOfMeasures(
        readOnly.sourceDeviceSubtypeId
      );
      this.selectedRegisterUnitOfMeasure = readOnly.registerUnitOfMeasureId;

      this.currentCalculatedRegisters =
        readOnly.detailCalculatedRegisterResourceModels;

      this.registerDialog = true;
      console.log(this.currentVirtualRegister);
    },

    async handleRegisterDelete(register) {
      const msg = `Are you sure you want to delete ${register.registerName}?`;
      const confirmed = await this.$refs.confirmDelete.confirm(msg);

      if (!confirmed) {
        return;
      }
      this.deleteRegister(register);
      console.log(register);
    },

    handleRegisterRegisterEdit(register) {
      this.registerAction = "Edit";
      this.currentCalculatedRegister = {
        physicalRegister: {
          registerId: register.registerId,
          registerName: register.registerName,
        },
        operation: register.operation,
        id: register.id,
      };
      this.registerRegisterDialog = true;
      console.log(register);
    },

    async handleRegisterRegisterDelete(register) {
      const msg = `Are you sure you want to delete ${register.registerName}?`;
      const confirmed = await this.$refs.confirmDelete.confirm(msg);

      if (!confirmed) {
        return;
      }
      this.deleteRegisterRegister(register);
      console.log(register);
    },
  },
};
</script>